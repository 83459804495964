import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const RestitucionBienesPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Restituciones de Bienes" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Restituciones de Bienes"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Restituciones de Bienes (Desocupación y Desahucio)"
      >
        <p>
        El fundamento legal lo encontramos en el libro I artículo 237 del Código Procesal  Civil y Mercantil  de Guatemala.
        </p>        
        <p>
        La demanda de desocupación puede ser entablada por el propietario, por el que ha entregado un inmueble
        a otro con la obligación de restituírselo o por los que comprueben tener derecho de poseer el inmueble
        por cualquier título legitimo; y se da en contra de todo simple tenedor y del intruso o en contra del
        que recibió el inmueble sujeto a la obligación antes dicha.
          </p>         
            <p>
           <strong> ¡¡¡Llámenos y haga su cita hoy!!! </strong>
              </p>     
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default RestitucionBienesPage

